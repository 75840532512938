import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


import { TranslateModule } from '@ngx-translate/core';
import { MessagingComponent } from './messaging/messaging.component';
import { VideoCallsComponent } from './video-calls/video-calls.component';
import { TeamsComponent } from './teams/teams.component';
import { VisitsComponent } from './visits/visits.component';
import { EhrVisualizationComponent } from './ehr-visualization/ehr-visualization.component';
import { AlertCenterComponent } from './alert-center/alert-center.component';
import { CustomFormsComponent } from './custom-forms/custom-forms.component';
import { UsersManagementComponent } from './users-management/users-management.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    RouterModule,
    TranslateModule
  ],
  declarations: [
    MessagingComponent,
    VideoCallsComponent,
    TeamsComponent,
    VisitsComponent,
    EhrVisualizationComponent,
    AlertCenterComponent,
    CustomFormsComponent,
    UsersManagementComponent
  ],
  exports: [
    MessagingComponent,
    VideoCallsComponent,
    TeamsComponent,
    VisitsComponent,
    EhrVisualizationComponent,
    AlertCenterComponent,
    CustomFormsComponent,
    UsersManagementComponent

  ],
  providers: []
})
export class ProductsModule { }
