import { Component, OnInit } from '@angular/core';
import { CustomNavigationService } from 'src/services/custom-navigation.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
  
  toggle1 = false;
  toggle2 = false;
  toggle3 = false;
  toggle4 = false;
  toggle5 = false;
  toggle6 = false;
  toggle7 = false;
  toggle8 = false;

  constructor(public cn: CustomNavigationService) { }

  ngOnInit(): void {
  }

}
