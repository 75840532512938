import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { SecurityComponent } from './security/security.component';

import { TranslateModule } from '@ngx-translate/core';
import { ArticlesComponent } from './articles/articles.component';
import { FaqsComponent } from './faqs/faqs.component';
import { RegisterOrgComponent } from './register-org/register-org.component';
import { BackofficeComponent } from './backoffice/backoffice.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { RecaptchaModule } from 'ng-recaptcha';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        RecaptchaModule
    ],
    declarations: [ 
        SecurityComponent, 
        ArticlesComponent,
        FaqsComponent,
        RegisterOrgComponent,
        BackofficeComponent,
        AboutUsComponent,
    ],
    exports:[ 
        SecurityComponent,
        ArticlesComponent,
        FaqsComponent,
        RegisterOrgComponent,
        BackofficeComponent,
        AboutUsComponent
    ],
    providers: []
})
export class MedxatModule { }
