import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CustomNavigationService {
  isCollapsed = false;
  
  constructor(public router: Router) { }

  async scrollTo(id, parent?) {
    if (id === 'contact' && this.router.url.indexOf('home') === -1) {
      await this.router.navigate(['/home']);
      setTimeout(() => {
        document.getElementById(id).scrollIntoView({ behavior: "auto", block: "center", inline: "nearest" });
      }, 100)
    } if (parent) {
      await this.router.navigate([`/${parent}`]);
      setTimeout(() => {
        document.getElementById(id).scrollIntoView({ behavior: "auto", block: "center", inline: "nearest" });
      }, 100)
    }
    else
      document.getElementById(id).scrollIntoView({ behavior: "auto", block: "center", inline: "nearest" });

  }
}
