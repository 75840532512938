import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ehr-visualization',
  templateUrl: './ehr-visualization.component.html',
  styleUrls: ['./ehr-visualization.component.css']
})
export class EhrVisualizationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
