import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subject } from 'rxjs';
import { CustomNavigationService } from 'src/services/custom-navigation.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, OnDestroy {
    focus: any;
    focus1: any;
    model = {
        left: true,
        middle: false,
        right: false
    };
    text;
    onDestroy$ = new Subject<any>();
    contactForm: any = {};
    msgResult = false;
    captcha = false;

    constructor(public firestore: AngularFirestore,
        public cn: CustomNavigationService) { }


    async ngOnInit() {

    }

    ngOnDestroy() {
        this.onDestroy$.next();
    }

    async sendMessage() {
        try {
            await this.firestore.collection('email')
                .add({
                    ...this.contactForm,
                    date: new Date(),
                    source: 'web'
                })
            this.contactForm = {};
            this.msgResult = true;
            setTimeout(() => { this.msgResult = false }, 7000)
        } catch (e) {
            window.alert('Ops! Error: sorry, try again later...')
        }
    }

    resolved(captchaResponse: string) {
        if (captchaResponse) this.captcha = true;
      }

}
