import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-forms',
  templateUrl: './custom-forms.component.html',
  styleUrls: ['./custom-forms.component.css']
})
export class CustomFormsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
