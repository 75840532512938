import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { SecurityComponent } from './medxat/security/security.component';
import { MessagingComponent } from './products/messaging/messaging.component';
import { PatientsComponent } from './solutions/patients/patients.component';
import { ArticlesComponent } from './medxat/articles/articles.component';
import { IntegrationsComponent } from './solutions/integrations/integrations.component';
import { CustomDevelopmentsComponent } from './solutions/custom-developments/custom-developments.component';
import { InternalCommunicationComponent } from './solutions/internal-communication/internal-communication.component';
import { VideoCallsComponent } from './products/video-calls/video-calls.component';
import { TeamsComponent } from './products/teams/teams.component';
import { FaqsComponent } from './medxat/faqs/faqs.component';
import { RegisterOrgComponent } from './medxat/register-org/register-org.component';
import { VisitsComponent } from './products/visits/visits.component';
import { EhrVisualizationComponent } from './products/ehr-visualization/ehr-visualization.component';
import { AlertCenterComponent } from './products/alert-center/alert-center.component';
import { CustomFormsComponent } from './products/custom-forms/custom-forms.component';
import { UsersManagementComponent } from './products/users-management/users-management.component';
import { BackofficeComponent } from './medxat/backoffice/backoffice.component';
import { AboutUsComponent } from './medxat/about-us/about-us.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'internal-communication', component: InternalCommunicationComponent },
  { path: 'voice-video-calls', component: VideoCallsComponent },
  { path: 'teams', component: TeamsComponent },

  { path: 'messaging', component: MessagingComponent },
  { path: 'telemedicine', component: PatientsComponent },
  { path: 'integrations', component: IntegrationsComponent },
  { path: 'custom-developments', component: CustomDevelopmentsComponent },
  { path: 'visits', component: VisitsComponent },
  { path: 'ehr-visualization', component: EhrVisualizationComponent },
  { path: 'alert-center', component: AlertCenterComponent },
  { path: 'custom-forms', component: CustomFormsComponent },
  { path: 'users-management', component: UsersManagementComponent },

  { path: 'security', component: SecurityComponent },
  { path: 'articles', component: ArticlesComponent },
  { path: 'backoffice', component: BackofficeComponent },
  { path: 'faqs', component: FaqsComponent },
  { path: 'register-org', component: RegisterOrgComponent },
  { path: 'about', component: AboutUsComponent },

  { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
