import { Component, OnInit } from '@angular/core';
import { CustomNavigationService } from 'src/services/custom-navigation.service';

@Component({
  selector: 'app-custom-developments',
  templateUrl: './custom-developments.component.html',
  styleUrls: ['./custom-developments.component.css']
})
export class CustomDevelopmentsComponent implements OnInit {

  constructor(public cnav: CustomNavigationService) { }

  ngOnInit(): void {
  }

}
