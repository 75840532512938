// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBHdPo5YfJLqHxeENa4Xl1NmkhUR4z3HD0',
    authDomain: 'medxat-app-demo.firebaseapp.com',
    databaseURL: 'https://medxat-app-demo.firebaseio.com',
    projectId: 'medxat-app-demo',
    storageBucket: 'medxat-app-demo.appspot.com',
    messagingSenderId: '812775800833',
    appId: '1:812775800833:web:81a53f5cf01c2d7e'
  }
};