import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-register-org',
  templateUrl: './register-org.component.html',
  styleUrls: ['./register-org.component.css']
})
export class RegisterOrgComponent implements OnInit {
  captcha = false;
  msgResult = false;
  contactForm: any = {
    org_role: 0
  };

  constructor(public firestore: AngularFirestore) { }


  ngOnInit(): void {
  }


  async sendMessage() {
    try {
      await this.firestore.collection('email')
        .add({
          ...this.contactForm,
          date: new Date(),
          source: 'web'
        })
      this.contactForm = {
        org_role: 0
      };
      this.msgResult = true;
      setTimeout(() => { this.msgResult = false }, 7000)
    } catch (e) {
      window.alert('Ops! Error: sorry, try again later...')
    }
  }

  resolved(captchaResponse: string) {
    if (captchaResponse) this.captcha = true;
  }

}
